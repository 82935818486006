import React from 'react'
import styled from 'styled-components'

// Components
import MainText from './MainText'
import SubText from './SubText'
import ButtonGroup from './ButtonGroup'

const IntroWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacings.medium};
  margin-left: ${({ theme }) => theme.spacings.medium};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: ${({ theme }) => theme.spacings.xLarge};
    margin-right: auto;
    margin-bottom: ${({ theme }) => theme.spacings.medium};
    margin-left: auto;
    max-width: ${({ theme }) => theme.breakpoints.lg};
  }
`

const Intro = () => {
  return (
    <>
      <IntroWrapper>
        <MainText />
        <SubText />
        <ButtonGroup />
      </IntroWrapper>
    </>
  )
}

export default Intro
