import React from 'react'
import styled from 'styled-components'

// Master Components
import { H3, Pb } from '../../../theme/Typography'

// Components
import { BtnArrow } from '../../../theme/Buttons'

// Data
import HomeServiceData from '../../../data/homeServices'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-count: 2;
  column-gap: ${({ theme }) => theme.spacings.xLarge};
  grid-row-gap: ${({ theme }) => theme.spacings.xLarge};
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacings.xxLarge};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    grid-template-columns: 1fr;
    column-count: 1;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: ${({ theme }) => theme.spacings.medium};
    max-width: ${({ theme }) => theme.breakpoints.sm};
    padding-right: ${({ theme }) => theme.spacings.large};
    padding-left: ${({ theme }) => theme.spacings.large};
  }
`

const Item = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.cultured};
  border-radius: ${({ theme }) => theme.spacings.small};
  padding: ${({ theme }) => theme.spacings.medium};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.shad2};
`

const ItemIcon = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xSmall};
`

const ItemHead = styled(H3)`
  color: ${({ theme }) => theme.colors.charleston};
  margin-bottom: ${({ theme }) => theme.spacings.small};
  letter-spacing: 1px;
`

const ItemText = styled(Pb)`
  color: ${({ theme }) => theme.colors.spanish};
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-grow: 1;
  }
`

const HomeGrid = () => {
  return (
    <>
      <Grid>
        {HomeServiceData.map((service, key) => {
          return (
            <React.Fragment key={key}>
              <Item>
                <ItemIcon>{service.icon}</ItemIcon>
                <ItemHead>{service.name}</ItemHead>
                <ItemText>{service.text}</ItemText>
                <BtnArrow path={`/${service.href}`} />
              </Item>
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}

export default HomeGrid
