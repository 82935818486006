import React from 'react'
import styled from 'styled-components'

// Master Component
import { H1 } from '../../../theme/Typography'

const I1 = styled(H1)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  line-height: 1;
  letter-spacing: 1px;
  margin-top: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.small};
`

const MainText = () => {
  return (
    <>
      <I1>We create websites that truly stand out.</I1>
    </>
  )
}

export default MainText
