import styled from 'styled-components'

export const ServiceSvg = styled.svg`
  height: ${({ theme }) => theme.spacings.large};
  fill: none;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;

  .primary {
    stroke: ${({ theme }) => theme.colors.indigo};
  }

  .secondary {
    stroke: ${({ theme }) => theme.colors.indigo};
  }
`
