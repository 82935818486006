import React from 'react'

// Master Component
import { ServiceSvg } from './ServiceSvg'

const AppIcon = () => {
  return (
    <>
      <ServiceSvg x="0px" y="0px" viewBox="0 0 24 24">
        <g>
          <path className="primary" d="M16,5v3" />
          <path
            className="primary"
            d="M12.9,19H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h9c1.1,0,2,0.9,2,2"
          />
          <path
            className="primary"
            d="M14.9,8H19c1.1,0,2,0.9,2,2v9c0,1.1-0.9,2-2,2h-4.1c-1.1,0-2-0.9-2-2v-9C12.9,8.9,13.8,8,14.9,8z"
          />
        </g>
        <g>
          <path
            className="secondary"
            d="M9.5,15.8c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2C9.7,15.9,9.6,15.8,9.5,15.8"
          />
          <path className="secondary" d="M16.1,18.8h1.8" />
        </g>
        <path fill="none" d="M0,0h24v24H0V0z" />
      </ServiceSvg>
    </>
  )
}

export default AppIcon
