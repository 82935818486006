import React from 'react'

// Master Component
import { ServiceSvg } from './ServiceSvg'

const WebIcon = () => {
  return (
    <>
      <ServiceSvg x="0px" y="0px" viewBox="0 0 24 24">
        <path
          className="primary"
          d="M6,3h12c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V6C3,4.3,4.3,3,6,3z"
        />

        <g>
          <path className="secondary" d="M15,8h2" />
          <path className="secondary" d="M8,7L7,8l1,1" />
          <path className="secondary" d="M11,7l1,1l-1,1" />
          <path className="secondary" d="M7,12.1h10" />
          <path className="secondary" d="M7,16h10" />
        </g>
        <path fill="none" d="M0,0h24v24H0V0z" />
      </ServiceSvg>
    </>
  )
}

export default WebIcon
