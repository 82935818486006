import React from 'react'
import styled from 'styled-components'

// Master Components
import { Pc } from '../../../theme/Typography'

// Components
import HomeContainer from '../HomeContainer'
import HomeHead from '../HomeHead'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-count: 2;
  column-gap: ${({ theme }) => theme.spacings.xLarge};
  grid-row-gap: ${({ theme }) => theme.spacings.xLarge};
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacings.xxLarge};
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};
  margin-left: ${({ theme }) => theme.spacings.xxLarge};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    grid-template-columns: 1fr;
    column-count: 1;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: ${({ theme }) => theme.spacings.medium};
    max-width: ${({ theme }) => theme.breakpoints.sm};
    padding-right: ${({ theme }) => theme.spacings.xLarge};
    padding-left: ${({ theme }) => theme.spacings.xLarge};
  }
`

const Item = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.cultured};
  border-radius: ${({ theme }) => theme.spacings.small};
  padding: ${({ theme }) => theme.spacings.large};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.shad2};
`

const ChanSvg = styled.svg`
  fill: ${({ theme }) => theme.colors.silver};
`

const ChannelText = styled(Pc)`
  color: ${({ theme }) => theme.colors.spanish};
  margin-right: ${({ theme }) => theme.spacings.medium};
  margin-left: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-grow: 1;
  }
`

const HomeChannel = () => {
  return (
    <>
      <HomeContainer>
        <HomeHead pre="CHANNELS" title="We're the capo when it comes to" />
        <Grid>
          <Item>
            <ChanSvg x="0" y="0" viewBox="0 0 300 120">
              <path d="M176.3 103.1c-5.1 4.9-10.7 4.2-16 1.8-5.7-2.4-10.9-2.5-16.9 0-7.5 3.2-11.4 2.3-16-1.8-25.4-26.2-21.6-66.1 7.3-67.6 7 .4 11.9 3.9 16 4.2 6.1-1.2 12-4.8 18.6-4.4 7.9.6 13.8 3.8 17.7 9.4-16.2 9.8-12.4 31.2 2.5 37.2-2.9 7.8-6.8 15.5-13.2 21.2zm-26.2-67.8c-.8-11.6 8.7-21.2 19.5-22.1 1.5 13.3-12.1 23.4-19.5 22.1z" />
            </ChanSvg>
          </Item>
          <Item>
            <ChanSvg x="0" y="0" viewBox="0 0 300 120">
              <path d="M129.5 62.5c0 13.4-10.5 23.4-23.4 23.4S82.7 76 82.7 62.5c0-13.5 10.5-23.4 23.4-23.4s23.4 9.8 23.4 23.4zm-10.2 0c0-8.4-6.1-14.2-13.2-14.2s-13.2 5.8-13.2 14.2c0 8.3 6.1 14.2 13.2 14.2 7.1-.1 13.2-5.9 13.2-14.2z" />
              <path d="M180.1 62.5c0 13.4-10.5 23.4-23.4 23.4s-23.4-9.9-23.4-23.4c0-13.5 10.5-23.4 23.4-23.4s23.4 9.8 23.4 23.4zm-10.3 0c0-8.4-6.1-14.2-13.2-14.2s-13.2 5.8-13.2 14.2c0 8.3 6.1 14.2 13.2 14.2s13.2-5.9 13.2-14.2z" />
              <path d="M228.5 40.5v41.9c0 17.3-10.2 24.3-22.2 24.3-11.3 0-18.1-7.6-20.7-13.8l8.9-3.7c1.6 3.8 5.5 8.3 11.8 8.3 7.7 0 12.5-4.8 12.5-13.7v-3.4h-.4c-2.3 2.8-6.7 5.3-12.3 5.3-11.7 0-22.4-10.2-22.4-23.3 0-13.2 10.7-23.4 22.4-23.4 5.6 0 10 2.5 12.3 5.2h.4v-3.8l9.7.1zm-9 22c0-8.2-5.5-14.2-12.5-14.2-7.1 0-13 6-13 14.2 0 8.1 5.9 14.1 13 14.1 7 0 12.5-5.9 12.5-14.1z" />
              <path d="M244.6 15.9v68.5h-10V15.9h10z" />
              <path d="M283.6 70.1l8 5.3c-2.6 3.8-8.8 10.4-19.5 10.4-13.3 0-23.2-10.3-23.2-23.4 0-13.9 10-23.4 22-23.4 12.1 0 18.1 9.6 20 14.9l1.1 2.7-31.2 12.9c2.4 4.7 6.1 7.1 11.3 7.1 5.2 0 8.8-2.6 11.5-6.5zm-24.5-8.4L280 53c-1.1-2.9-4.6-5-8.7-5-5.2.1-12.5 4.7-12.2 13.7z" />
              <path d="M44.8 56.4v-9.9h33.4c.3 1.7.5 3.8.5 6 0 7.4-2 16.6-8.6 23.2-6.4 6.6-14.5 10.2-25.3 10.2C24.8 85.8 8 69.5 8 49.5s16.8-36.3 36.8-36.3c11.1 0 18.9 4.3 24.9 10l-7 7c-4.2-4-10-7.1-17.9-7.1-14.6 0-26 11.8-26 26.4s11.4 26.4 26 26.4c9.5 0 14.9-3.8 18.3-7.3 2.8-2.8 4.6-6.8 5.4-12.3l-23.7.1z" />
            </ChanSvg>
          </Item>
          <Item>
            <ChanSvg x="0" y="0" viewBox="0 0 300 120">
              <path d="M184.2,84.2c-16.5,12.2-40.5,18.7-61.1,18.7c-28.9,0-55-10.7-74.7-28.5c-1.5-1.4-0.2-3.3,1.7-2.2C71.4,84.5,97.7,92,124.9,92c18.3,0,38.5-3.8,57-11.7C184.6,79.1,187,82.1,184.2,84.2" />
              <path d="M191.1,76.3c-2.1-2.7-14-1.3-19.3-0.6c-1.6,0.2-1.9-1.2-0.4-2.2c9.4-6.6,25-4.7,26.8-2.5c1.8,2.2-0.5,17.8-9.3,25.2c-1.4,1.1-2.7,0.5-2.1-1C188.8,90.2,193.2,79,191.1,76.3" />
              <path d="M172.2,26.5V20c0-1,0.7-1.6,1.6-1.6h28.9c0.9,0,1.7,0.7,1.7,1.6v5.5c0,0.9-0.8,2.1-2.2,4.1l-15,21.4c5.6-0.1,11.5,0.7,16.5,3.5c1.1,0.6,1.4,1.6,1.5,2.5V64c0,0.9-1,2-2.1,1.5c-8.9-4.7-20.7-5.2-30.6,0c-1,0.5-2.1-0.5-2.1-1.5v-6.6c0-1.1,0-2.8,1.1-4.4L189,28.1h-15.1C172.9,28.1,172.2,27.5,172.2,26.5" />
              <path d="M66.6,66.8h-8.8c-0.8-0.1-1.5-0.7-1.6-1.5V20.1c0-0.9,0.8-1.6,1.7-1.6h8.2c0.9,0,1.5,0.7,1.6,1.5v5.9h0.2c2.1-5.7,6.2-8.4,11.6-8.4c5.5,0,9,2.7,11.4,8.4c2.1-5.7,7-8.4,12.2-8.4c3.7,0,7.7,1.5,10.2,4.9c2.8,3.8,2.2,9.3,2.2,14.2l0,28.5c0,0.9-0.8,1.6-1.7,1.6H105c-0.9-0.1-1.6-0.8-1.6-1.6V41.2c0-1.9,0.2-6.7-0.2-8.5c-0.7-3-2.6-3.9-5.2-3.9c-2.1,0-4.4,1.4-5.3,3.7c-0.9,2.3-0.8,6.1-0.8,8.7v23.9c0,0.9-0.8,1.6-1.7,1.6h-8.8c-0.9-0.1-1.6-0.8-1.6-1.6l0-23.9c0-5,0.8-12.5-5.4-12.5c-6.3,0-6.1,7.2-6.1,12.5v23.9C68.3,66.1,67.6,66.8,66.6,66.8" />
              <path d="M229.4,17.6c13.1,0,20.1,11.2,20.1,25.5c0,13.8-7.8,24.7-20.1,24.7c-12.8,0-19.8-11.2-19.8-25.2C209.5,28.5,216.6,17.6,229.4,17.6 M229.4,26.8c-6.5,0-6.9,8.8-6.9,14.4c0,5.5-0.1,17.3,6.8,17.3c6.8,0,7.1-9.5,7.1-15.3c0-3.8-0.2-8.4-1.3-12C234.2,28,232.2,26.8,229.4,26.8" />
              <path d="M266.4,66.8h-8.8c-0.9-0.1-1.6-0.8-1.6-1.6l0-45.2c0.1-0.8,0.8-1.5,1.7-1.5h8.2c0.8,0,1.4,0.6,1.6,1.3v6.9h0.2c2.5-6.2,5.9-9.1,12-9.1c4,0,7.8,1.4,10.3,5.3c2.3,3.6,2.3,9.7,2.3,14.1v28.4c-0.1,0.8-0.8,1.4-1.7,1.4h-8.8c-0.8-0.1-1.5-0.7-1.6-1.4V40.9c0-4.9,0.6-12.2-5.5-12.2c-2.1,0-4.1,1.4-5.1,3.6c-1.2,2.8-1.4,5.5-1.4,8.6v24.3C268.1,66.1,267.4,66.8,266.4,66.8" />
              <path d="M164.8,58.5c-1.6-2.2-3.3-4-3.3-8.2V36.7c0-5.8,0.4-11.1-3.9-15.1c-3.4-3.2-9-4.4-13.3-4.4c-8.4,0-17.7,3.1-19.7,13.5c-0.2,1.1,0.6,1.7,1.3,1.8l8.5,0.9c0.8,0,1.4-0.8,1.5-1.6c0.7-3.6,3.7-5.3,7.1-5.3c1.8,0,3.9,0.7,4.9,2.3c1.2,1.8,1.1,4.3,1.1,6.4v1.1c-5.1,0.6-11.8,1-16.6,3.1c-5.5,2.4-9.4,7.2-9.4,14.4c0,9.2,5.8,13.7,13.2,13.7c6.3,0,9.7-1.5,14.5-6.4c1.6,2.3,2.1,3.4,5.1,5.9c0.7,0.3,1.5,0.3,2.1-0.2l0,0c1.8-1.6,5-4.3,6.7-5.8C165.5,60.3,165.4,59.3,164.8,58.5z M147.5,54.6c-1.4,2.5-3.6,4-6.1,4c-3.4,0-5.4-2.6-5.4-6.4c0-7.5,6.7-8.9,13.1-8.9v1.9C149.1,48.7,149.2,51.5,147.5,54.6z" />
              <path d="M49.4,58.5c-1.6-2.2-3.3-4-3.3-8.2V36.7c0-5.8,0.4-11.1-3.9-15.1c-3.4-3.2-9-4.4-13.3-4.4c-8.4,0-17.7,3.1-19.7,13.5c-0.2,1.1,0.6,1.7,1.3,1.8l8.5,0.9c0.8,0,1.4-0.8,1.5-1.6c0.7-3.6,3.7-5.3,7.1-5.3c1.8,0,3.9,0.7,4.9,2.3c1.2,1.8,1.1,4.3,1.1,6.4v1.1c-5.1,0.6-11.8,1-16.6,3.1c-5.5,2.4-9.4,7.2-9.4,14.4c0,9.2,5.8,13.7,13.2,13.7c6.3,0,9.7-1.5,14.5-6.4c1.6,2.3,2.1,3.4,5.1,5.9c0.7,0.3,1.5,0.3,2.1-0.2v0l0,0c1.8-1.6,5-4.3,6.7-5.8C50.1,60.3,50,59.3,49.4,58.5z M32.1,54.6c-1.4,2.5-3.6,4-6.1,4c-3.4,0-5.3-2.6-5.3-6.4c0-7.5,6.7-8.9,13.1-8.9v1.9C33.7,48.7,33.8,51.5,32.1,54.6z" />
            </ChanSvg>
          </Item>
          <Item>
            <ChanSvg x="0" y="0" viewBox="0 0 300 120">
              <path d="M84.6 13v58H94V50.8L108 71h11.2l-15.9-22.7L118.1 31h-11.2L94 46.1V13z" />
              <path d="M136.1 65.2c6.8 0 7.6-6.8 7.6-14.4s-1.1-14.4-7.6-14.4c-6.8 0-7.6 6.8-7.6 14.4s.8 14.4 7.6 14.4m-17.6-14.4c0-13 6.1-20.9 17.7-20.9s17.7 7.9 17.7 20.9-6.1 20.9-17.7 20.9-17.7-7.6-17.7-20.9M175.4 65.2c6.1 0 6.8-7.9 6.8-14.4 0-7.2-.7-14.8-6.8-14.8-5.8 0-7.2 8.3-7.2 14.8 0 10.1 1.4 14.4 7.2 14.4m-7.2-29.5c1.4-2.2 4.3-5.8 10.4-5.8 9.7 0 13.7 8.3 13.7 20.9 0 11.2-4 20.9-16.9 20.9-10.4 0-16.6-6.1-16.6-18V13h9.4v22.7zM214.7 65.2c6.8 0 7.6-6.8 7.6-14.4s-1.1-14.4-7.6-14.4c-6.8 0-7.6 6.8-7.6 14.4-.4 7.6.7 14.4 7.6 14.4M197 50.8c0-13 6.1-20.9 17.7-20.9s17.7 7.9 17.7 20.9-6.1 20.9-17.7 20.9S197 64.1 197 50.8" />
              <path d="M197.4 84.7c-10.8 0-18.4.4-22.3.7-4 .7-7.6 1.8-10.8 3.6-4 2.2-6.5 5.4-7.6 9h-1.1c-.7-3.2-2.9-6.1-6.5-8.3-2.9-1.8-6.1-2.9-10.1-4-4-.7-9-1.1-15.1-1.1H80l-17 9.8c4.3.4 9 .7 14.4.7h15.1l22.7-.4 16.6-.4c7.2 0 12.6 1.1 16.2 2.9 3.6 1.8 5.8 5 6.5 9.7h2.9c.7-3.2 2.2-5.8 4-7.9 1.8-1.8 4-2.9 6.8-4 2.9-.7 6.8-1.1 11.9-1.1h24.5V84l-7.2.7zM211.1 84.7h10.1v10.1h-10.1zM226.9 84.7H237v10.1h-10.1z" />
            </ChanSvg>
          </Item>
        </Grid>
        <ChannelText>
          Our team can handle all your design and development needs when it comes to putting your
          brand, eBook, or business out there on the most powerful platforms and channels. We are
          well-versed in handling all stages of development and are always happy to guide your way
          to success on each of them and answer any questions you may have.
        </ChannelText>
      </HomeContainer>
    </>
  )
}

export default HomeChannel
