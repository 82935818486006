import React from 'react'
import styled from 'styled-components'

// Master Component
import { H5 } from '../../../theme/Typography'

// const I3 = styled(H3)`
const Ip = styled(H5)`
  color: ${({ theme }) => theme.colors.platinum};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  font-weight: 300;
  text-align: center;
  font-family: 'Overpass';

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }) => theme.spacings.small};
    max-width: ${({ theme }) => theme.breakpoints.md};
    padding-right: ${({ theme }) => theme.spacings.medium};
    padding-left: ${({ theme }) => theme.spacings.medium};
  }
`

const SubText = () => {
  return (
    <>
      <Ip>We’ll make you a website your customers can’t refuse.</Ip>
    </>
  )
}

export default SubText
