import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const IntroBlock = styled.div`
  display: flex;
  flex-direction: row;
`

const IntroButton = styled.button`
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.pink};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  cursor: pointer;
  padding-top: 2px;
  margin-right: ${({ theme }) => theme.spacings.xSmall};
  margin-left: ${({ theme }) => theme.spacings.xSmall};
  border-radius: ${({ theme }) => theme.spacings.medium};
  height: ${({ theme }) => theme.spacings.large};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 160px;
    text-align: center;
    vertical-align: middle;
    padding-top: 4px;
    line-height: ${({ theme }) => theme.spacings.large};
    height: ${({ theme }) => theme.spacings.large};
  }

  span {
    background: ${({ theme }) => theme.colors.pink};
    height: 100%;
    width: 0;
    border-radius: ${({ theme }) => theme.spacings.medium};
    position: absolute;
    right: ${props => (props.right ? '0' : '')};
    left: ${props => (props.right ? '' : '0')};
    bottom: 0;
    z-index: -1;
    transition: 0.4s;
  }

  &:hover span {
    width: 102%;
  }

  :focus {
    outline: 0;
  }

  :hover {
    border-radius: ${({ theme }) => theme.spacings.medium};
  }
`

const ButtonGroup = () => {
  return (
    <>
      <IntroBlock>
        <Link to="/portfolio">
          <IntroButton right>
            <span />
            PORTFOLIO
          </IntroButton>
        </Link>
        <Link to="contact">
          <IntroButton>
            <span />
            CONTACT
          </IntroButton>
        </Link>
      </IntroBlock>
    </>
  )
}

export default ButtonGroup
