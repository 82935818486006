import React from 'react'

// Master Component
import { ServiceSvg } from './ServiceSvg'

const PrintIcon = () => {
  return (
    <>
      <ServiceSvg x="0px" y="0px" viewBox="0 0 24 24">
        <g>
          <path
            className="primary"
            d="M19,3H9.7C9,3,8.4,3.2,7.9,3.6L4.2,6.3C3.4,6.9,3,7.8,3,8.8V19c0,1.1,0.9,2,2,2h10.5c0.9,0,1.8-0.4,2.4-1.2
		l2.7-3.6c0.3-0.3,0.4-0.8,0.4-1.2V5C21,3.9,20.1,3,19,3z"
          />
          <line className="primary" x1="17" y1="7.3" x2="20.6" y2="3.9" />
          <line className="primary" x1="17" y1="20.6" x2="17" y2="7.3" />
          <line className="primary" x1="17" y1="7.3" x2="3.4" y2="7.3" />
          <line className="primary" x1="10.4" y1="7.3" x2="15.3" y2="3" />
        </g>
        <line className="secondary" x1="8" y1="11" x2="12" y2="11" />
        <path fill="none" d="M0,0h24v24H0V0z" />
      </ServiceSvg>
    </>
  )
}

export default PrintIcon
