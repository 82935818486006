import React from 'react'
import styled from 'styled-components'

// Components
import Seo from '../components/Seo'
import Header from '../components/Header'
import Intro from '../components/Intro/'
import Curve from '../components/Curve'
import HomeServices from '../components/Services/HomeServices'
import Footer from '../components/Footer'
import HomePortfolio from '../components/Portfolio/HomePortfolio'
import HomeChannel from '../components/Channels/HomeChannel'

const HomeWrapper = styled.div`
  background: ${({ theme }) => theme.colors.ghost};
`

const Home = () => {
  return (
    <>
      <Seo title="Home" />
      <Header />
      <Intro />
      <Curve />
      <HomeWrapper>
        <HomeServices />
      </HomeWrapper>
      <Curve flip />
      <HomePortfolio />
      <Curve />
      <HomeWrapper>
        <HomeChannel />
      </HomeWrapper>
      <Curve flip />
      <Footer />
    </>
  )
}

export default Home
