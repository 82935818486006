import React from 'react'

// Components
import HomeContainer from '../HomeContainer'
import HomeGrid from './HomeGrid'
import HomeHead from '../HomeHead'

const PreServices = () => {
  return (
    <>
      <HomeContainer>
        <HomeHead pre="SERVICES" title="What we can do for you" />
        <HomeGrid />
      </HomeContainer>
    </>
  )
}

export default PreServices
