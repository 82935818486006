import React from 'react'

// Components
import {
  WebIcon,
  AppIcon,
  BrandIcon,
  PrintIcon,
  EbookIcon,
  MiscIcon,
} from '../src/components/Services/Icons'

const HomeServiceData = [
  {
    icon: <WebIcon />,
    name: `Web Design & Development`,
    text: `We can build from scratch a responsive website that looks great, works flawlessly on any
    device, and provides end-users with a seamless experience that inspires them to take
    action.`,
    href: `services#web`,
  },
  {
    icon: <AppIcon />,
    name: `App Design & Development`,
    text: `Time to step up your game with a dedicated app? We can help you create iOS and Android
    compatible apps that look amazing and provide users with a secure and enjoyable
    experience.`,
    href: `services#app`,
  },
  {
    icon: <BrandIcon />,
    name: `Brand Development`,
    text: `We create a brand development strategy that works in conjunction with your marketing
    strategy to help you develop a unique voice and identity for your business.`,
    href: `services#brand`,
  },
  {
    icon: <PrintIcon />,
    name: `Print & Packaging`,
    text: `Prints and packaging that perfectly align with your branding can push your brand
    awareness to the next level. We can create creative, out-of-the-box designs that
    instantly catch the attention.`,
    href: `services#print`,
  },
  {
    icon: <EbookIcon />,
    name: `eBook Development`,
    text: `The Web Mobsters crew loves supporting aspiring authors. Our eBook development services
    are created to ensure your eBook takes the internet by storm.`,
    href: `services#ebook`,
  },
  {
    icon: <MiscIcon />,
    name: `Misc`,
    text: `If you do not see a service we have. No worries! Contact our team to see if we can
    assist you with your idea.`,
    href: `contact`,
  },
]

export default HomeServiceData
