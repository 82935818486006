import React from 'react'
import styled from 'styled-components'

// Master Components
import { P, H2 } from '../../theme/Typography'

const PreText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }) => theme.spacings.large};
  }
`

const PreHead = styled(P)`
  color: ${({ theme }) => theme.colors.pink};
  margin-bottom: ${({ theme }) => theme.spacings.xSmall};
  text-align: center;
  line-height: 1;
`

const Head = styled(H2)`
  color: ${props =>
    props.invert === true
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.charleston};
  text-align: center;
  line-height: 1;
  letter-spacing: 1px;
`

const HomeHead = ({ pre, title, invert }) => {
  return (
    <>
      <PreText>
        <PreHead>{pre}</PreHead>
        <Head invert={invert}>{title}</Head>
      </PreText>
    </>
  )
}

export default HomeHead
