import React from 'react'

// Master Component
import { ServiceSvg } from './ServiceSvg'

const BrandIcon = () => {
  return (
    <>
      <ServiceSvg x="0px" y="0px" viewBox="0 0 24 24">
        <g>
          <path className="primary" d="M5,7v10" />
          <path className="primary" d="M19,17V7" />
          <path className="primary" d="M7,19h10" />
          <path className="primary" d="M17,5H7" />
        </g>
        <g>
          <path
            className="secondary"
            d="M20,21h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v2C21,20.6,20.6,21,20,21z"
          />
          <path
            className="secondary"
            d="M6,21H4c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v2C7,20.6,6.6,21,6,21z"
          />
          <path
            className="secondary"
            d="M20,7h-2c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v2C21,6.6,20.6,7,20,7z"
          />
          <path
            className="secondary"
            d="M6,7H4C3.4,7,3,6.6,3,6V4c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v2C7,6.6,6.6,7,6,7z"
          />
        </g>
        <path fill="none" d="M0,0h24v24H0V0z" />
      </ServiceSvg>
    </>
  )
}

export default BrandIcon
