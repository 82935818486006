import React from 'react'

// Master Component
import { ServiceSvg } from './ServiceSvg'

const MiscIcon = () => {
  return (
    <>
      <ServiceSvg x="0px" y="0px" viewBox="0 0 24 24">
        <rect fill="none" width="24" height="24" />
        <path
          className="primary"
          d="M7.1,20.1l-3.2-3.2C3.3,16.3,3,15.6,3,14.8V9.2c0-0.8,0.3-1.6,0.9-2.1l3.2-3.2C7.7,3.3,8.4,3,9.2,3h5.5
	c0.8,0,1.6,0.3,2.1,0.9L20,7.1c0.7,0.6,1,1.3,1,2.1v5.5c0,0.8-0.3,1.6-0.9,2.1L16.9,20c-0.6,0.6-1.3,0.9-2.1,0.9H9.2
	C8.4,21,7.7,20.7,7.1,20.1z"
        />
        <g>
          <path
            className="secondary"
            d="M12,12.7l1.3-0.7c0.7-0.4,1.1-1.1,1.1-1.8C14.3,8.9,13.2,7.9,12,8c-1.1,0-2.1,0.7-2.4,1.8"
          />
          <path
            className="secondary"
            d="M12.1,15.9c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1s0-0.1,0.1-0.1C12.1,15.8,12.1,15.8,12.1,15.9L12.1,15.9"
          />
        </g>
      </ServiceSvg>
    </>
  )
}

export default MiscIcon
