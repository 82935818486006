import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Master Components
import { BtnPink } from '../../../theme/Buttons'

// Components
import HomeHead from '../HomeHead'
import PortfolioGrid from './PortfolioGrid'

const PortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin-top: ${({ theme }) => theme.spacings.small};
  margin-right: auto;
  margin-left: auto;
  padding: 0 ${({ theme }) => theme.spacings.medium};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: -${({ theme }) => theme.spacings.small};
  }
`

const HomePortfolio = () => {
  const { allMdx } = useStaticQuery(query)
  const portfolio = allMdx.edges

  return (
    <>
      <PortWrapper>
        <HomeHead pre="PORTFOLIO" title="Latest works" invert />
        <PortfolioGrid data={portfolio} />
        <BtnPink href="portfolio" text="View more of our projects" />
      </PortWrapper>
    </>
  )
}

const query = graphql`
  {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/portfolio/" }
        frontmatter: { isFeature: { eq: true } }
      }
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            featureImg {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            keywords
            category
            excerpt
            slug
          }
        }
      }
    }
  }
`

export default HomePortfolio
