import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings.xxLarge};
  margin-right: auto;
  margin-left: auto;
  max-width: ${({ theme }) => theme.breakpoints.md};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: ${({ theme }) => theme.spacings.large};
  }
`

const HomeContainer = ({ children }) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  )
}

export default HomeContainer
