import React from 'react'

// Master Component
import { ServiceSvg } from './ServiceSvg'

const EbookIcon = () => {
  return (
    <>
      <ServiceSvg x="0px" y="0px" viewBox="0 0 24 24">
        <g>
          <path className="secondary" d="M11,7h4" />
          <path className="secondary" d="M11,11h4" />
        </g>
        <g>
          <path className="primary" d="M20,18H7" />
          <path
            className="primary"
            d="M7,21h12c0.6,0,1-0.4,1-1v-5H7c-1.7,0-3,1.3-3,3l0,0C4,19.7,5.3,21,7,21z"
          />
          <path className="primary" d="M7,3v12" />
          <path className="primary" d="M4,18V6c0-1.7,1.3-3,3-3h12c0.6,0,1,0.4,1,1v11" />
        </g>
        <path fill="none" d="M0,0h24v24H0V0z" />
      </ServiceSvg>
    </>
  )
}

export default EbookIcon
